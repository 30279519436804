export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Release Date',
        minWidth: 80,
        key: 'ReleaseDate',
        sortable: true,
    },

    {
        title: 'Release Version',
        key: 'VersionName',
        minWidth: 80,
    },

	{
        title: 'Release Title',
        key: 'ReleaseTitle',
        minWidth: 80,
    }
]
